import React, {Fragment, useEffect, useState} from "react"
import {Button, CardTitle, Col, Form, Input, Label, Modal, ModalBody, Progress, Row, Spinner, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import Select from "react-select";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {Link} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const ImportationEtudiant = props => {
    const [cycle, setCycle] = useState([
        {value: 1, label: "Licence"},
        {value: 2, label: "Mastère"}
    ])
    const [selectCycle, setSelectCycle] = useState("");
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [cloture, setCloture] = useState(0);
    const [excel, setExcel] = useState(0);
    const [modal, setModal] = useState(false)
    const [modalLicence, setModalLicence] = useState(false);
    const [file, setFile] = useState(null)
    const [list, setList] = useState([]);
    const [uploadPercent, setUploadPercent] = useState(0)
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [fileLicence, setFileLicence] = useState(null);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPage: 50,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "75",
                value: 75,
            },
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "order",
            text: "Order",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "cin",
            text: "CIN",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "etudiant",
            text: "Etudiant",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "specialite_name",
            text: "Specialite",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "niveau_name",
            text: "Niveau",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        // {
        //     dataField: "Suppression",
        //     isDummyField: true,
        //     text: props.t("Suppression"),
        //     style: {textAlign: "center"},
        //     headerStyle: (colum, colIndex) => {
        //         return {textAlign: "center"}
        //     },
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, row) =>
        //         cloture != 1 ? (
        //             delet == 1 ? (
        //                 row.count == 0 ? (
        //                     <div style={{display: "flex", justifyContent: "center"}}>
        //                         <i
        //                             style={{color: "#ED6464", cursor: "pointer"}}
        //                             onClick={() => toggle(row.id)}
        //                             className="fas fa-trash-alt"
        //                         ></i>
        //                     </div>
        //                 ) : (
        //                     <div style={{display: "flex", justifyContent: "center"}}>
        //                         <i
        //                             style={{color: "#a7a3a3", cursor: "pointer"}}
        //                             className="fas fa-trash-alt"
        //                             title="Ce grade déja utilisé"
        //                         ></i>
        //                     </div>
        //                 )
        //             ) : (
        //                 <div style={{display: "flex", justifyContent: "center"}}>
        //                     <i
        //                         style={{color: "#a7a3a3", cursor: "pointer"}}
        //                         className="fas fa-trash-alt"
        //                         title="Vous n'avez pas le droit de grade"
        //                     ></i>
        //                 </div>
        //             )
        //         ) : (
        //             <div style={{display: "flex", justifyContent: "center"}}>
        //                 <i
        //                     style={{color: "#a7a3a3", cursor: "pointer"}}
        //                     className="fas fa-trash-alt"
        //                     title="Année clôturée"
        //                 ></i>
        //             </div>
        //         ),
        // },
    ]

    const {SearchBar} = Search
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe d'examen")
        );
        setExcel(typeArticleSubMenu.sous_menu[0].exel)
        const res = await API.post("groupe_examen/liste_etudiant_controle",{
            annee_id,
            semestre_id:props.semestre.value
        }).then(res => {
            setOrders(res.data.Etudiant)
        })
        setLoading(true)
        setSelectNiveau("")
        setSelectCycle("")
    }, [props.semestre])
    const getCycle = async (e) => {
        setSelectCycle(e)
        setSelectNiveau("")
        if(e.value === 1 ){
            const resNiveau = await API.get("niveau/select").then(resNiveau => {
                setNiveau(resNiveau.data.Niveau)
            })
        }else{
            setNiveau([
                {value: 1, label: "1 ère Année"},
                {value: 2, label: "2 ème Année"}
            ])
        }

    }

    const toggle = () => {
        if (selectCycle === "") {
            toast.error("⛔ Veuillez choisir un cycle", {
                containerId: "A",
            })
        } else if (selectNiveau !== "") {
            setModal(!modal)
            setUploadPercent(0)
        }else {
            toast.error("⛔ Veuillez choisir un niveau", {
                containerId: "A",
            })
        }
    }
    const toggleLience = () => {
        if (selectCycle === "") {
            toast.error("⛔ Veuillez choisir un cycle", {
                containerId: "A",
            })
        } else if (selectNiveau !== "") {
            setModalLicence(!modalLicence)
            setUploadPercent(0)
        }else {
            toast.error("⛔ Veuillez choisir un niveau", {
                containerId: "A",
            })
        }
    }
    const AfficherTous = () => {
        API.post("groupe_examen/liste_etudiant_controle",{
            annee_id,
            semestre_id:props.semestre.value
        }).then(res => {
            setOrders(res.data.Etudiant)
        })
        setLoading(true)
        setList([])
    }
    const addFile = e => {
        var file = e.target.files[0]
        setFile(file)
    }
    const addFileLicence = e => {
        var fileLicence = e.target.files[0]
        setFileLicence(fileLicence)
    }

    const uploadFile = async () => {
        let data = new FormData()
        data.append("file", file)
        data.append("cycle_id", selectCycle.value)
        data.append("niveau_id", selectNiveau.value)
        data.append("semestre_id", props.semestre.value)
        data.append("annee_id", annee_id)
        data.append("user_id", user_id)
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent
                let percent = Math.round((loaded * 100) / total)
                if (percent < 100) {
                    setUploadPercent(percent)
                }
            },
        }
        API.post("groupe_examen/importation_liste_mastere_controle", data, options).then(res => {
            if(res.data.errNum === 200 || res.data.errNum === "200"){
                setList(res.data.Etudiant.etudiants_inexistants)
                setMsg(res.data.Etudiant.message)
                setUploadPercent(100)
                setModal(!modal)
            }else{
                toast.error("⛔ Le fichier doit être au format Excel (xlsx ou xls) !", {
                    containerId: "A",
                })
            }
        })
    }
    const uploadFileLicence = async () => {
        let data = new FormData()
        data.append("file", fileLicence)
        data.append("cycle_id", selectCycle.value)
        data.append("niveau_id", selectNiveau.value)
        data.append("semestre_id", props.semestre.value)
        data.append("annee_id", annee_id)
        data.append("user_id", user_id)
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent
                let percent = Math.round((loaded * 100) / total)
                if (percent < 100) {
                    setUploadPercent(percent)
                }
            },
        }
        API.post("groupe_examen/importation_liste_licence_controle", data, options).then(res => {
            if(res.data.errNum === 200 || res.data.errNum === "200"){
                setList(res.data.Etudiant.etudiants_inexistants)
                setMsg(res.data.Etudiant.message)
                setUploadPercent(100)
                setModal(!modal)
            }else{
                toast.error("⛔ Le fichier doit être au format Excel (xlsx ou xls) !", {
                    containerId: "A",
                })
            }
        })
    }
    return (
        <React.Fragment>
            <CardTitle style={{color: "#556ee6", marginTop: "15px"}} className="h4">
                Critéres de Recherches
            </CardTitle>
            <Form className="mt-4">
                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Cycle:</Label>
                            <Select
                                options={cycle}
                                isSearchable={true}
                                onChange={e => getCycle(e)}
                                value={selectCycle}
                            />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Niveau</Label>
                            <Select
                                options={niveau}
                                isSearchable={true}
                                onChange={e => setSelectNiveau(e)}
                                value={selectNiveau}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Col lg="12">
                    <div className="text-center mt-4">
                        {cloture !== 1 ? (
                            excel === 1 ? (
                                <a className="col-md-3" onClick={toggle} rel="noreferrer">
                                    <button style={{backgroundColor: "#05b5a8", borderColor: "#05b5a8"}}
                                            className="btn btn-primary btn btn-primary mb-2 me-2">
                                        <i className="fas fa-file-excel font-size-18 align-middle me-2"></i>
                                        Exportation liste des étudiants de mastère
                                    </button>
                                </a>
                            ) : null
                        ) : null}
                        {cloture !== 1 ? (
                            excel === 1 ? (
                                <a className="col-md-3" onClick={toggleLience} rel="noreferrer">
                                    <button style={{backgroundColor: "#3971d8", borderColor: "#3971d8"}}
                                            className="btn btn-primary btn btn-primary mb-2 me-2">
                                        <i className="fas fa-file-excel font-size-18 align-middle me-2"></i>
                                        Exportation liste des étudiants de licence
                                    </button>
                                </a>
                            ) : null
                        ) : null}
                        <a className="col-md-3" onClick={AfficherTous} rel="noreferrer">
                            <button style={{backgroundColor: "#042bb6", borderColor: "#042bb6"}}
                                    className="btn btn-primary btn btn-primary mb-2 me-2">
                                <i className="fas fa-list font-size-18 align-middle me-2"></i>
                                Afficher tous
                            </button>
                        </a>
                    </div>
                </Col>
            </div>
            {list.length == 0 ? (
                <div>
                    {loading ? (
                        <Row className="mt-4">
                            <Col xs="12">
                                <ToolkitProvider
                                    keyField="id"
                                    data={orders}
                                    columns={Columns()}
                                    search
                                    bootstrap4
                                >
                                    {toolkitProps => (
                                    <div>
                                        <div>
                                            <div className="mb-3 row" style={{display: "flex"}}>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{width: "100%"}}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {"Chargement"}

                        </h4>
                    </div>
                )}
            </div>
            ) : (null)}
            {list.length > 0 ? (
                <Fragment>
                    <CardTitle style={{color: "#ea0b0b", marginTop: "15px"}} className="h4">
                        {msg}
                    </CardTitle>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <table className="table table-bordered mt-1">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Ligne dans le fichier excel</th>
                                <th>Cin étudiant</th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            {list.map((el, index) => (
                                <tbody key={index}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{el.ligne}</td>
                                    <td>{el.cin}</td>
                                    <td>{el.message}</td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                </Fragment>
            ) : (null)}
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "20px",
                        color: "#556ee6",
                        textAlign: "center",
                    }}
                    toggle={toggle}
                >
                    Importation Liste Etudiants mastère pour la session de contôle
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        {uploadPercent <= 0 ? (
                            <Input
                                onChange={e => addFile(e)}
                                className="form-control mt-4"
                                type="file"
                                id="formFile"
                            />
                        ) : null}
                        {uploadPercent > 0 && uploadPercent < 100 ? (
                            <Progress
                                striped
                                className="progress-xl"
                                color="success"
                                value={uploadPercent}
                            >
                                {uploadPercent}%
                            </Progress>
                        ) : null}
                        {uploadPercent === 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                Success
                                <i
                                    style={{
                                        paddingLeft: "5px",
                                        color: "darkcyan",
                                        paddingTop: "3.5px",
                                    }}
                                    className="fas fa-check me-1"
                                />
                            </div>
                        ) : null}
                        {uploadPercent !== 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn mb-2 me-2 mt-4"
                                    onClick={uploadFile}
                                >
                                    <i className="fas fa-cloud-upload-alt me-1"/>
                                    Upload
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalLicence} toggle={toggleLience} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "20px",
                        color: "#556ee6",
                        textAlign: "center",
                    }}
                    toggle={toggleLience}
                >
                    Importation Liste Etudiants licence pour la session de contôle
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        {uploadPercent <= 0 ? (
                            <Input
                                onChange={e => addFileLicence(e)}
                                className="form-control mt-4"
                                type="file"
                                id="formFile"
                            />
                        ) : null}
                        {uploadPercent > 0 && uploadPercent < 100 ? (
                            <Progress
                                striped
                                className="progress-xl"
                                color="success"
                                value={uploadPercent}
                            >
                                {uploadPercent}%
                            </Progress>
                        ) : null}
                        {uploadPercent === 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                Success
                                <i
                                    style={{
                                        paddingLeft: "5px",
                                        color: "darkcyan",
                                        paddingTop: "3.5px",
                                    }}
                                    className="fas fa-check me-1"
                                />
                            </div>
                        ) : null}
                        {uploadPercent !== 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn mb-2 me-2 mt-4"
                                    onClick={uploadFileLicence}
                                >
                                    <i className="fas fa-cloud-upload-alt me-1"/>
                                    Upload
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ImportationEtudiant
ImportationEtudiant.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
    t: PropTypes.any,
}
