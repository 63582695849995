import React, {Fragment, useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import AddEtudiant from "./AddEtudiant"
import DataTableEtudiant from "./DataTableEtudiant"
import ExportEtudiant from "./ExportEtudiant"
import ImportEtudiant from "./ImportEtudiant"
import Orientation from "./Orientation"
import PageAutorisation from "../PageAutorisation";

const Etudiant = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [excel, setExcel] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Etudiants")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setExcel(typeArticleSubMenu.sous_menu[0].exel)
    }, [])
    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableEtudiant/>
        } else if (section === 2) {
            return <AddEtudiant setSection={setSection} back={back}/>
        } else if (section === 3) {
            return <ExportEtudiant setSection={setSection} back={back}/>
        } else if (section === 4) {
            return <ImportEtudiant setSection={setSection} back={back}/>
        } else if (section === 5) {
            return <Orientation setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Etudiant | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    Etudiant
                                </NavLink>
                            </NavItem>
                            {cloture != 1 ? (
                                add === 1 ? (
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 2,
                                            })}
                                            onClick={() => {
                                                setSection(2)
                                            }}
                                        >
                                            Ajouter etudiant
                                        </NavLink>
                                    </NavItem>
                                ) : null
                            ) : null}
                            {excel == 1 ? (
                                <Fragment>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 3,
                                            })}
                                            onClick={() => {
                                                setSection(3)
                                            }}
                                        >
                                            Exportation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 4,
                                            })}
                                            onClick={() => {
                                                setSection(4)
                                            }}
                                        >
                                            Importation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 5,
                                            })}
                                            onClick={() => {
                                                setSection(5)
                                            }}
                                        >
                                            Orientation
                                        </NavLink>
                                    </NavItem>

                                </Fragment>
                            ) : null}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Etudiant)
