import React, {useEffect, useState} from "react"
import {Button, CardTitle, Col, Form, Input, Label, Modal, ModalBody, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import Select from "react-select";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"

const ImpressionReception = props => {
    const [list, setList] = useState([])
    const [cycle, setCycle] = useState([
        {value: 1, label: "Licence"},
        {value: 2, label: "Mastère"}
    ])
    const [selectCycle, setSelectCycle] = useState("");
    const [selectSemestre, setSelectSemestre] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [matiere, setMatiere] = useState([])
    const [selectMatiere, setSelectMatiere] = useState("");
    const [imp, setImp] = useState(0);
    const [excel, setExcel] = useState(0);
    const [add, setAdd] = useState(0);
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())
    const [date, setDate] = useState("");
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe d'examen")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setExcel(typeArticleSubMenu.sous_menu[0].exel)
        setImp(typeArticleSubMenu.sous_menu[0].imp)
        API.post("examen_parametrage/getBy_annee_semestre", {
            semestre_id: props.semestre.value, annee_id
        }).then(resAnnee => {
            setDate(new Date(resAnnee.data.params.date_debut))
            setMinDate(new Date(resAnnee.data.params.date_debut))
            setMaxDate(new Date(resAnnee.data.params.date_fin))
        })
        const resNiveau = await API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
        const res = await API.post("surveillance_examen/verif_chevauchement", {
            annee_id,
            semestre_id: props.semestre.value
        }).then(res => {
            setList(res.data.elements)
        })
    }, [props.semestre])
    const getCycle = async (e) => {
        setSelectCycle(e)
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectMatiere("")
    }
    const getSpecialite = async (e) => {
        setSelectNiveau(e)
        setSelectSpecialite("")
        setSelectMatiere("")
        const res = await API.post("group/get_list_spec_by_cycle", {
            niveau_id: e.value,
            semestre_id: [props.semestre],
            cycle_id: selectCycle.value
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getMatiere = async (e) => {
        setSelectSpecialite(e)
        setSelectMatiere("")
        const res = await API.post("matiere/get_non_passe_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: props.semestre.value,
            annee_id
        }).then(res => {
            setMatiere(res.data.Matiere)
        })
    }
    const isWeekday = date => {
        const day = getDay(date)
        return day !== 0 && day !== 6;
    }
    const uploadPDFReception2 = async () => {
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        var niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0
        window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/reception2/'+convertDate+'/'+ niveau_id + '/' + specialite_id + '/' + annee_id + '/' + props.semestre.value, '_blank')
    }
    const uploadPDFReceptionParSalle = async () => {
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        var niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0
        var cycle_id = selectCycle !== "" && selectCycle != null ? selectCycle.value : 0
        var matiere_id = selectMatiere !== "" && selectMatiere != null ? selectMatiere.value : 0
        window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/repartitionGroupeExamanParSalle/'+props.semestre.value+'/'+ convertDate + '/' + cycle_id + '/' + niveau_id + '/' + specialite_id + '/' + matiere_id+ '/' + annee_id, '_blank')
    }
    const uploadPDFReception1 = async () => {
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        var niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0
        window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/reception1/'+convertDate+'/'+ niveau_id + '/' + specialite_id + '/' + annee_id + '/' + props.semestre.value, '_blank')

    }
    return (
        <React.Fragment>
            <CardTitle style={{color: "#556ee6",marginTop: "15px"}} className="h4">
                Critéres de Recherches
            </CardTitle>
            <Form className="mt-4">
                <Row>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Date</Label>
                            <DatePicker
                                className="form-control ddate"
                                minDate={minDate}
                                maxDate={maxDate}
                                selected={date}
                                onChange={(date) => setDate(date)}
                                dateFormat="dd/MM/yyyy"
                                filterDate={isWeekday}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Cycle:</Label>
                            <Select
                                options={cycle}
                                isSearchable={true}
                                onChange={e => getCycle(e)}
                                value={selectCycle}
                            />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Niveau</Label>
                            <Select
                                options={niveau}
                                isSearchable={true}
                                onChange={e => getSpecialite(e)}
                                value={selectNiveau}
                            />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Spécialité</Label>
                            <Select
                                options={specialite}
                                isSearchable={true}
                                onChange={e => getMatiere(e)}
                                value={selectSpecialite}
                            />
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Matière</Label>
                            <Select
                                options={matiere}
                                isSearchable={true}
                                onChange={e => setSelectMatiere(e)}
                                value={selectMatiere}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Col lg="12">
                    <div className="text-center mt-4">
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    style={{*/}
                        {/*        backgroundColor: "#761C19",*/}
                        {/*        borderColor: "#761C19",*/}
                        {/*    }}*/}
                        {/*    className="btn btn-success mb-2 me-2"*/}
                        {/*    onClick={search}*/}
                        {/*>*/}
                        {/*    Rechercher*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    style={{*/}
                        {/*        backgroundColor: "#761C19",*/}
                        {/*        borderColor: "#761C19",*/}
                        {/*    }}*/}
                        {/*    className="btn btn-success mb-2 me-2"*/}
                        {/*    onClick={searchAll}*/}
                        {/*>*/}
                        {/*    Afficher tout*/}
                        {/*</button>*/}

                        {cloture !== 1 ? (
                            imp === 1 ? (
                                <a className="col-md-3" onClick={uploadPDFReception1} rel="noreferrer">
                                    <button style={{ backgroundColor: "#67088d",borderColor: "#67088d"}} className="btn btn-primary btn btn-primary mb-2 me-2">
                                        <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                        Exporter réception 1
                                    </button>
                                </a>
                            ) : null
                        ) : null}
                        {cloture !== 1 ? (
                            imp === 1 ? (
                                <a className="col-md-3" onClick={uploadPDFReception2} rel="noreferrer">
                                    <button style={{backgroundColor: "#d052de",borderColor: "#d052de"}} className="btn btn-primary btn btn-primary mb-2 me-2">
                                        <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                        Exporter réception 2
                                    </button>
                                </a>
                            ) : null
                        ) : null}
                        {cloture !== 1 ? (
                            imp === 1 ? (
                                <a className="col-md-3" onClick={uploadPDFReceptionParSalle} rel="noreferrer">
                                    <button style={{ backgroundColor: "#05c1a9",borderColor: "#05c1a9"}} className="btn btn-primary btn btn-primary mb-2 me-2">
                                        <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                        Exporter réception des groupes des examens par salles
                                    </button>
                                </a>
                            ) : null
                        ) : null}
                    </div>
                </Col>

            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />

        </React.Fragment>
    )
}

export default ImpressionReception
ImpressionReception.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
}
