import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button, CardTitle, Form, Label,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Select from "react-select";

const Affectation = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    const [niveau, setNiveau] = useState([]);
    const [selectNiveau, setSelectNiveau] = useState("")
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
    ])
    const [cycle, setCycle] = useState([
        {value: 1, label: "Licence"},
        {value: 2, label: "Mastère"}
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [specialite, setSpecialite] = useState([]);
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [matiere, setMatiere] = useState([]);
    const [selectMatiere, setSelectMatiere] = useState("");
    const [selectCycle, setSelectCycle] = useState("");
    const [type, setType] = useState([]);
    const [selectType, setSelectType] = useState("");
    const [enseignant, setEnseignant] = useState([]);
    const [selectEnseignant, setSelectEnseignant] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [modalDetail, setModalDetail] = useState(false)
    const [title, setTitle] = useState("")
    const [msg, setMsg] = useState("")
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    // Data Table
    const [orders, setOrders] = useState([])

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "cin",
            text: props.t("CIN"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "enseignant",
            text: props.t("Enseignant"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "matiere",
            text: props.t("Matière"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "type",
            text: props.t("Type matière"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "groupe",
            text: props.t("Groupe"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },

        {
            dataField: "Suppression",
            isDummyField: true,
            text: props.t("Suppression"),
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <i
                        style={{ color: "#ED6464", cursor: "pointer" }}
                        onClick={() => toggle(row.groupe_ens_mat_id)}
                        className="fas fa-trash-alt"
                    ></i>
                </div>
            ),
        },
    ]

    const { SearchBar } = Search

    //

    useEffect(async () => {
        //    Enseignant
        const resE = await API.get("enseignant/select").then(resE => {
            setEnseignant(resE.data.select_enseignant)
        })
        await API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
        const res = await API.post("emploi/list_grp_ens_mat",{
            semestre_id:props.semestre.value,annee_id
        }).then(res => {
            setOrders(res.data.grp_ens_mat)
        })
        setLoading(true)
    }, [props.semestre])

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    const toggleDelete = async () => {
        const res = await API.post("emploi/delete_grp_ens_mat", {
            id: id,
            semestre_id:props.semestre.value,
            annee_id,
            user_id
        }).then(res => {
            if(res.data.errNum === "500" || res.data.errNum === 500){
                toggleDetail("Erreur", "⛔ "+res.data.msg);
            }else{
                const resD = API.post("emploi/list_grp_ens_mat",{
                    annee_id,
                    semestre_id: props.semestre.value
                }).then(resD => {
                    setOrders(resD.data.grp_ens_mat)
                })
                setLoading(true)
            }

        })
    }

    useEffect(() => {
        setModal(false)
    }, [orders])
    const search = async () => {
        const group_id = selectGroupe !== "" && selectGroupe != null ? selectGroupe.value : 0;
        const matiere_id = selectMatiere !== "" && selectMatiere != null ? selectMatiere.value : 0;
        const enseignant_id = selectEnseignant !== "" && selectEnseignant != null ? selectEnseignant.value : 0;
        setOrders([])
        const res = await API.post("emploi/search_grp_ens_mat", {
            enseignant_id: enseignant_id,
            matiere_id: matiere_id,
            semestre_id: props.semestre.value,
            group_id: group_id,
            annee_id
        }).then(res => {
            setOrders(res.data.grp_ens_mat)
        })

    }
    const searchAll = async () => {

        const resD = API.post("emploi/list_grp_ens_mat", {annee_id, semestre_id: props.semestre.value}).then(resD => {
            setOrders(resD.data.grp_ens_mat)
        })
        setLoading(true)
    }
    const getCycle = async (e) => {
        setSelectCycle(e)
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectGroupe("")
    }

    const getSpecialite = async (e) => {
        setSelectNiveau(e)
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectGroupe("")
        const res = await API.post("group/get_list_spec_by_cycle", {
            niveau_id: e.value,
            semestre_id: [props.semestre],
            cycle_id:selectCycle.value
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getMatiere = async (e) => {
        setSelectSpecialite(e)
        setSelectGroupe("")
        setSelectMatiere("")
        const res = await API.post("matiere/get_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: props.semestre.value
        }).then(res => {
            setMatiere(res.data.Matiere)
        })
        const resG = await API.post("group/get_by_cyc_niv_sem_spec", {
            annee_id: annee_id,
            cycle_id: selectCycle.value,
            niveau_id: selectNiveau.value,
            semestre_id: props.semestre.value,
            specialite_id: e.value,
        }).then(resG => {
            setGroupe(resG.data.groups)
        })
    }
    const toggleDetail = async (title,msg) => {
        setModalDetail(!modalDetail)
        if(modalDetail !== true){
            setTitle(title)
            setMsg(msg)
        }
    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <CardTitle style={{color: "#556ee6"}} className="h4">
                                            Critéres de Recherches
                                        </CardTitle>
                                        <Form className="mt-4">
                                            <Row>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Cycle : </Label>
                                                        <Select
                                                            options={cycle}
                                                            isSearchable={true}
                                                            value={selectCycle}
                                                            onChange={e => getCycle(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Niveau : </Label>
                                                        <Select
                                                            options={niveau}
                                                            isSearchable={true}
                                                            value={selectNiveau}
                                                            onChange={e => getSpecialite(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                                        <Select
                                                            options={specialite}
                                                            isSearchable={true}
                                                            value={selectSpecialite}
                                                            onChange={e => getMatiere(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">{"Matière :"} </Label>
                                                        <Select
                                                            options={matiere}
                                                            isSearchable={true}
                                                            value={selectMatiere}
                                                            onChange={e => setSelectMatiere(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">{"Groupe :"} </Label>
                                                        <Select
                                                            options={groupe}
                                                            isSearchable={true}
                                                            value={selectGroupe}
                                                            isClearable={true}
                                                            onChange={e => setSelectGroupe(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">{"Enseignant :"} </Label>
                                                        <Select
                                                            options={enseignant}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={selectEnseignant}
                                                            onChange={e => setSelectEnseignant(e)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div style={{display: "flex", justifyContent: "center"}}>

                                            <Col lg="12">
                                                <div className="text-center mt-4">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={search}
                                                    >
                                                        Rechercher
                                                    </button>
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={searchAll}
                                                    >
                                                        Afficher tout
                                                    </button>
                                                </div>
                                            </Col>

                                        </div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon" />
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                            {props.t("Chargement")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{ width: "100% !important", textAlign: "center !important" }}
                    toggle={toggle}
                    tag="h4"
                >
                    {props.t("Suppression")} {props.t("affectation de groupe")}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>
                                {props.t("Êtes-Vous sûr de vouloir supprimer cette")}{" "}
                                {props.t("affectation de groupe")} ?
                            </p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    {props.t("Oui")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    {props.t("Non")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalDetail} toggle={toggleDetail} centered={true}>
                <div className="modal-header" style={{ width: "100% !important",justifyContent: "center !important", margin: "0 auto",fontSize: "17px"}} toggle={toggleDetail}>
                    {title}
                </div>
                <ModalBody>
                    <div style={{fontFamily: "Montserrat, sans-serif", FontSize: "14px",FontWeight: "700",LineHeight: "18.375px"}}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>{msg}</p>
                        </div>
                        <div className="hvr-push" style={{display: "flex", justifyContent: "space-around",marginBottom: "15px", marginTop:"17px" }}>
                            <div>
                                <Button onClick={toggleDetail} color="danger" className="btn-rounded ">
                                    <i style={{ color: "white" }} className="far fa-thumbs-down"></i>
                                    Fermer
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Affectation))
Affectation.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
    semestre: PropTypes.object,
}
