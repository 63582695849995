import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button, CardTitle, Form, Label,
} from "reactstrap"
import {withRouter} from "react-router"
import {Slide, toast, ToastContainer} from "react-toastify";
import Select from "react-select";
import Switch from "react-switch";

const DataTableEnseignant = props => {
    const [loading, setLoading] = useState(false)
    const [modalDetail, setModalDetail] = useState(false);
    const [id, setId] = useState("")
    const [orders, setOrders] = useState([])
    const [add, setAdd] = useState(0);
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [view, setView] = useState(0);
    const [detailExam, setDetailExam] = useState([])
    const [ligne, setLigne] = useState("");
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 10,
            },
            {
                text: "150",
                value: 150,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN ",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "nom",
            text: "Nom & prénom",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "active",
            isDummyField: true,
            text: "Etat",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                cloture != 1 ? (
                    add === 1 ? (
                        <Switch
                            uncheckedIcon={<Offsymbol/>}
                            checkedIcon={<OnSymbol/>}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#34c38f"
                            onChange={() => activeEtat(row)}
                            checked={row.active}
                        />
                    ) : (
                        <Switch
                            uncheckedIcon={<Offsymbol/>}
                            checkedIcon={<OnSymbol/>}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#34c38f"

                            checked={row.active}
                            disabled={true}
                        />
                    )
                ) : (
                    <Switch
                        uncheckedIcon={<Offsymbol/>}
                        checkedIcon={<OnSymbol/>}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#34c38f"
                        checked={row.active}
                        disabled={true}
                        readOnly={true}
                    />
                )
            ),
        },
    ]
    const Offsymbol = () => {
        return (
            <div
                title="Activé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                A
            </div>
        )
    }

    const OnSymbol = props => {
        return (
            <div
                title="Désactivé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                D
            </div>
        )
    }
    const activeEtat = async row => {
        var active = row.active
        var pram = row.id
        if (active === 0) {
            const res = await API.post("surveillance_examen/update_state_ens", {
                id: pram,
                semestre_id: props.semestre.value,
                user_id,annee_id
            }).then(res => {
                toast.success(" 🔓✔️ Etat Activé Avec succès", {
                    containerId: "A",
                })
                API.post("enseignant/list_avec_seance",{annee_id,semestre_id:props.semestre.value}).then(resE => {
                    setOrders(resE.data.enseignants)
                    setLoading(true)
                })
            })
        } else if (active === 1) {
            const res = await API.post("surveillance_examen/update_state_ens", {
                id: pram,
                semestre_id: props.semestre.value,
                user_id,annee_id
            }).then(res => {
                toast.success(" 🔒❌ Etat Désactivé Avec succès ", {
                    containerId: "A",
                })
                API.post("enseignant/list_avec_seance",{annee_id,semestre_id:props.semestre.value}).then(resE => {
                    setOrders(resE.data.enseignants)
                    setLoading(true)
                })
            })
        }
    }
    const {SearchBar} = Search
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Calendrier de surveillance")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setView(typeArticleSubMenu.sous_menu[0].view)
        const res = await API.post("enseignant/list_avec_seance", {
            annee_id,semestre_id:props.semestre.value
        }).then(res => {
            setOrders(res.data.enseignants)
        })
        setLoading(true)
    }, [props.semestre])

    useEffect(() => {

    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row style={{marginTop:"15px"}}>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>


                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>



            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}


export default withRouter(DataTableEnseignant)
DataTableEnseignant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    semestre: PropTypes.object,
}
