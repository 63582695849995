import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button, CardTitle, Form, Label,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import Select from "react-select";

const DataTableGroupe = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [niveau, setNiveau] = useState([])
  const [cycle, setCycle] = useState([
    {value: 1, label: "Licence"},
    {value: 2, label: "Mastère"}
  ])
  const [selectCycle, setSelectCycle] = useState("");
  const [selectNiveau, setSelectNiveau] = useState("")
  const [semestre, setSemestre] = useState([
    {value: 1, label: "Semestre 1"},
    {value: 2, label: "Semestre 2"},
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [departement, setDepartement] = useState([]);
  const [selectDepartement, setSelectDepartement] = useState("");
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "niveau",
      text: "Niveau",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: "semestre",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "specialite",
      text: "Spécialité",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Groupes cours",
      isDummyField: true,
      text: "Groupes cours",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/ViewGroupCour?id=" + row.cyc_niv_sem_spec_id}>
            <i
              style={{ color: "darkmagenta", cursor: "pointer" }}
              className="fas fa-users-cog"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "Groupes TD",
      isDummyField: true,
      text: "Groupes TD",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/ViewGroupTD?id=" + row.cyc_niv_sem_spec_id}>
            <i
              style={{ color: "darkmagenta", cursor: "pointer" }}
              className="fas fa-users-cog"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "Groupes TP",
      isDummyField: true,
      text: "Groupes TP",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/ViewGroupTP?id=" + row.cyc_niv_sem_spec_id}>
            <i
              style={{ color: "darkmagenta", cursor: "pointer" }}
              className="fas fa-users-cog"
            ></i>
          </Link>
        </div>
      ),
    }
  ]
  const { SearchBar } = Search
  useEffect(async () => {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      const res = await API.post("group/list",{annee_id:annee_id}).then(res => {
        setOrders(res.data.Groups)
      })
      //niveau
      const resNiveau = await API.get("niveau/select").then(resNiveau => {
          setNiveau(resNiveau.data.Niveau)
        })
      const resDepartement = await API.get("departement/select").then(resDepartement => {
        setDepartement(resDepartement.data.Departement)
        setSelectDepartement("")
      })
    setLoading(true)
  }, [])
  const searchAll = async () => {
    setSelectCycle("")
    setSelectNiveau("")
    const res = await API.post("group/list",{annee_id:annee_id}).then(res => {
      setOrders(res.data.Groups)
    })
  }
  const search = async () => {
    const cycle_id = selectCycle !== "" && selectCycle != null ? selectCycle.value : 0;
    const semestre_id = selectSemestre != "" && selectSemestre != null ? selectSemestre.value : 0;
    const niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0;

    setOrders([])
    const res = await API.post("matiere/search", {
      cycle_id: cycle_id,
      semestre_id: semestre_id,
      niveau_id: niveau_id
    }).then(res => {
      setOrders(res.data.Matiere)
    })
  }
  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    if (cycle_id == 1) {
      const res = await API.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await API.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }
  const getSpec = async event => {
    var departement_id = event.value
    setSelectSpecialite("")
    setSelectDepartement(event)
    const res = await API.post("specialite/select_by_departement_cycle", {
      departement_id: departement_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.Specialite)
    })
  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <CardTitle style={{color: "#556ee6"}} className="h4">
                      Critéres de Recherches
                    </CardTitle>
                    <Form className="mt-4">
                      <Row>
                        <Col lg="2">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Semestre</Label>
                            <Select
                                options={semestre}
                                isSearchable={true}
                                value={selectSemestre}
                                onChange={e => setSelectSemestre(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="2">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Cycle : </Label>
                            <Select
                                options={cycle}
                                isSearchable={true}
                                value={selectCycle}
                                onChange={e => getNiveau(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="2">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Niveau : </Label>
                            <Select
                                options={niveau}
                                isSearchable={true}
                                value={selectNiveau}
                                onChange={e => setSelectNiveau(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input2">Département :</Label>
                            <Select
                                options={departement}
                                isSearchable={true}
                                value={selectDepartement}
                                onChange={e => getSpec(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Spécialité :</Label>
                            <Select
                                options={specialite}
                                isSearchable={true}
                                value={selectSpecialite}
                                onChange={e => setSelectSpecialite(e)}
                            />
                          </div>
                        </Col>
                      </Row>

                    </Form>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <Col lg="12">
                        <div className="text-center mt-4">
                          <button
                              type="button"
                              style={{
                                backgroundColor: "#761C19",
                                borderColor: "#761C19",
                              }}
                              className="btn btn-success mb-2 me-2"
                              onClick={search}
                          >
                            Rechercher
                          </button>
                          <button
                              type="button"
                              style={{
                                backgroundColor: "#761C19",
                                borderColor: "#761C19",
                              }}
                              className="btn btn-success mb-2 me-2"
                              onClick={searchAll}
                          >
                            Afficher tout
                          </button>
                        </div>
                      </Col>

                    </div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>

    </React.Fragment>
  )
}
export default withRouter(DataTableGroupe)
DataTableGroupe.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
