import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, Modal, ModalHeader, ModalBody, CardTitle, Form, Label} from "reactstrap"
import { withRouter } from "react-router"
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"
const DataTableHistoriqueSeance = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [description, setDescription] = useState("")
  const [dateDebut, setDateDebut] = useState(new Date());
  const [dateFin, setDateFin] = useState(new Date());
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "150",
        value: 150,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const Columns = () => [
    {
      dataField: "user",
      text: "User",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "groupe",
      text: "Groupe",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: "Salle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "heure_debut",
      text: "Heure début de seance",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "heure_fin",
      text: "Heure fin de seance",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

    {
      dataField: "date",
      text: "Date",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

  ]

  const { SearchBar } = Search

  useEffect(async () => {
    // const res = await API.post("seance/getHistorique",{annee_id}).then(res => {
    //   setOrders(res.data.hist_matiere)
      setLoading(true)
    // })
  }, [])

  const toggle = row => {
    setModal(!modal)
    setDescription(row.designation)
  }
  const search = async () => {
    let convertDate = 0;
    let convertDateFin = 0;
    if (dateDebut !== "" && dateDebut !== null && dateFin!=="" && dateFin!=null) {
      const dateNaiss = new Date(dateDebut);
      let month = "" + (dateNaiss.getMonth() + 1);
      let day = "" + dateNaiss.getDate();
      let year = dateNaiss.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      convertDate = [year, month, day].join("-");

      //date fin
      const dateNaissFin = new Date(dateFin);
      let monthFin = "" + (dateNaissFin.getMonth() + 1);
      let dayFin = "" + dateNaissFin.getDate();
      let yearFin = dateNaissFin.getFullYear();

      if (monthFin.length < 2) monthFin = "0" + monthFin;
      if (dayFin.length < 2) dayFin = "0" + dayFin;

      convertDateFin = [yearFin, monthFin, dayFin].join("-");
    }

    setOrders([])
    const res = await API.post("seance/getHistorique", {
      date_debut: convertDate,
      date_fin: convertDateFin,
      annee_id
    }).then(res => {
      setLoading(true)
      setOrders(res.data.hist_matiere)
    })

  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <CardTitle style={{color: "#556ee6"}} className="h4">
                      Critéres de Recherches
                    </CardTitle>
                    <Form className="mt-4">
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Date de début</Label>
                            <DatePicker
                                className="form-control ddate"
                                selected={dateDebut}
                                onChange={(dateDebut) => setDateDebut(dateDebut)}
                                dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Date de fin</Label>
                            <DatePicker
                                className="form-control ddate"
                                selected={dateFin}
                                onChange={(dateFin) => setDateFin(dateFin)}
                                dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </Col>

                      </Row>

                    </Form>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <Col lg="12">
                        <div className="text-center mt-4">
                          <button
                              type="button"
                              style={{
                                backgroundColor: "#761C19",
                                borderColor: "#761C19",
                              }}
                              className="btn btn-success mb-2 me-2"
                              onClick={search}
                          >
                            Rechercher
                          </button>
                        </div>
                      </Col>

                    </div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      pagination={paginationFactory(pageOptions)}
                      hover
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggle}
          tag="h4"
        >
          Historique
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {/* {description} */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(DataTableHistoriqueSeance)
DataTableHistoriqueSeance.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
